<template>
  <footer v-if="data" class="footer">
    <div class="footer__top">
      <div class="footer-container">
        <div class="footer__main">
          <nav class="footer__main-links">
            <div v-for="group in data.content.main_links" :key="group._uid">
              <h2 class="eyebrow">{{ group.title }}</h2>
              <ul>
                <li v-for="link in group.link" :key="link._uid">
                  <CTA :blok="{ ...link, style: 'tertiary' }" />
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <div class="footer__logo">
          <NuxtLink aria-label="Visit Shiftkey Homepage" to="/">
            <img
              src="/img/ui/logo-mark.svg"
              width="30"
              height="48"
              alt="ShiftKey Logo Mark"
            />
          </NuxtLink>
        </div>
      </div>
    </div>
    <div
      v-if="
        data.content.download_links ||
        data.content.social_links ||
        data.content.utility_links ||
        data.content.copyright
      "
      class="footer__bottom"
    >
      <div class="footer-container">
        <div
          v-if="data.content.download_links || data.content.social_links"
          class="footer__share"
        >
          <div v-if="data.content.download_links" class="footer__download">
            <ul class="footer__download-links">
              <li v-for="link in data.content.download_links" :key="link._uid">
                <CTA :blok="{ ...link, style: 'tertiary' }" />
              </li>
            </ul>
          </div>
          <div v-if="data.content.social_links" class="footer__social">
            <ul class="footer__social-links">
              <li v-for="link in data.content.social_links" :key="link._uid">
                <NuxtLink
                  :id="link.id ? link.id : undefined"
                  :to="
                    link.link.linktype === 'url'
                      ? link.link.cached_url
                      : '/' + link.link.cached_url
                  "
                  :rel="
                    link.link.target === '_blank'
                      ? 'nofollow noopener'
                      : undefined
                  "
                  :target="
                    link.link.target === '_blank' ? link.link.target : ''
                  "
                >
                  <img
                    :alt="`Visit ShiftKey on ${capitalizeFirstLetter(
                      link.channel
                    )}`"
                    :src="`/img/ui/icon-${link.channel}.svg`"
                    width="24"
                    height="24"
                  />
                </NuxtLink>
              </li>
            </ul>
          </div>
        </div>
        <div
          v-if="data.content.utility_links || data.content.copyright"
          class="footer__utility"
        >
          <ul class="footer__utility-links">
            <template v-if="data.content.utility_links">
              <li v-for="link in data.content.utility_links" :key="link._uid">
                <CTA
                  :blok="{
                    ...link,
                    style: 'tertiary',
                  }"
                />
              </li>
            </template>
            <li>
              <a class="optanon-show-settings">{{ data.content.cookieText }}</a>
            </li>
            <li v-if="data.content.copyright">{{ data.content.copyright }}</li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { useStoriesStore } from '@/stores/Stories';
import { GlobalFooterProps } from '@/types';

// Get Stories
const store = useStoriesStore();
await store.fetchData();

// @ts-ignore
const data: GlobalFooterProps | undefined = store.getGlobalFooter;

const capitalizeFirstLetter = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1);
</script>

<style lang="scss">
@use 'sass:math';

.footer {
  $breakpoint-value: #{math.div(1172, 16) + 'rem'};
  $breakpoint-min: '(min-width: #{$breakpoint-value})';
  $breakpoint-max: '(max-width: #{$breakpoint-value})';

  background-color: var(--dark-gray);
  border-radius: var(--section-border-radius) var(--section-border-radius) 0 0;
  z-index: 1;

  .eyebrow,
  li,
  a {
    color: var(--white);
  }

  &-container {
    @include container;

    padding-block: var(--spacing-m);
  }

  &__share,
  &__main-links,
  &__download-links,
  &__social-links,
  &__utility-links {
    @media all and #{$mq-m-min} {
      display: flex;
    }
  }

  &__top,
  &__bottom {
    .footer-container {
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  &__top .footer-container {
    display: flex;
  }

  &__bottom {
    border-block-start: 1px solid rgb(255 255 255 / 20%);

    @media all and #{$breakpoint-min} {
      .footer-container {
        display: flex;
      }
    }
  }

  &__bottom,
  &__share,
  &__download-links,
  &__social-links {
    align-items: center;
  }

  &__main-links,
  &__social-links,
  &__utility-links {
    a {
      padding-block-end: 0;
      text-decoration: none;
    }
  }

  a {
    display: inline-block;
    vertical-align: top;

    &:focus-visible {
      outline: 1px solid currentcolor;
      outline-offset: 4px;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    .cta:hover {
      text-decoration: revert;
    }
  }

  &__download {
    &-links {
      li {
        margin-block-end: var(--spacing-xxs);

        @media all and #{$mq-m-min} {
          margin-block-end: 0;
          margin-inline-end: 40px;
        }
      }
    }
  }

  ul {
    margin-block: 0;
    padding-inline: 0;

    li::before {
      content: none;
    }
  }

  &__social {
    ul {
      @media all and #{$mq-m-max} {
        margin-block-end: calc(var(--spacing-s));
        margin-block-start: var(--spacing-s);
      }
    }

    &-links {
      display: flex;

      img {
        filter: invert(1);
        height: 24px;
        width: auto;
      }
    }
  }

  &__utility {
    &-links {
      opacity: 0.6;

      li {
        font-size: 0.875rem;

        @media all and #{$mq-s-max} {
          margin-block-end: var(--spacing-xxs);
        }
      }
    }

    @media all and #{$breakpoint-max} {
      margin-block-start: var(--spacing-xs);
    }

    @media all and #{$mq-m-max} {
      margin-block-start: 0;
    }
  }

  &__social-links li:not(:last-of-type) {
    margin-inline-end: 32px;
  }

  &__utility-links li:not(:last-of-type) {
    @media all and #{$mq-m-min} {
      margin-inline-end: 16px;
    }
  }

  &__main-links {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);

    @media all and #{$mq-m-min} {
      flex-direction: row;
      gap: columns(2);
    }

    .eyebrow {
      margin-block-end: var(--spacing-xxs);
    }

    li:not(:last-of-type) {
      margin-block-end: var(--spacing-xxxs);
    }
  }
}
.optanon-show-settings:hover {
  text-decoration: underline;
  cursor: pointer;
  text-underline-offset: 6px;
}
</style>
